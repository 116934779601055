const staticColors = {
  offWhite: `hsla(60, 100%, 98%, 1)`,
  offWhiteLayer: `hsla(60, 100%, 98%, .7)`,
  grayOne: `hsla(0, 0%, 90%, 1)`,
  grayTwo: `hsla(0, 0%, 30%, 1)`,
  grayThree: `hsla(0, 0%, 95%, .5)`,
  grayFour: `hsla(0, 0%, 100%, 1)`,
  grayFive: `hsla(0, 0%, 100%, .7)`,
  graySix: `hsla(0, 0%, 100%, .85)`,
  black: `hsla(270, 28%, 7%, 1)`,
  blackLayer: `hsla(270, 28%, 7%, .7)`,
  blackLayerTwo: `hsla(270, 28%, 7%, .85)`,
  purpleOne: `hsla(259, 89%, 74%, 1)`,
  purpleTwo: `hsla(249, 66%, 60%, 1)`,
  purpleThree: `hsl(237, 87%, 88%)`,
  purpleFour: `hsla(259, 89%, 74%, .7)`,
  purpleFive: `hsla(249, 66%, 60%, .7)`,
  purpleSix: `hsla(259, 89%, 74%, .3)`,
  purpleSeven: `hsla(249, 66%, 60%, .3)`,
}

const colors = {
  dark: {
    primary: staticColors.purpleOne,
    text: staticColors.offWhite,
    'text-alternate': staticColors.purpleThree,
    background: staticColors.black,
    card: staticColors.black,
    'card-transparent': staticColors.blackLayer,
    'background-transparent': staticColors.blackLayer,
    'header-transparent': staticColors.blackLayerTwo,
    focus: staticColors.grayTwo,
    outline: staticColors.purpleThree,
    border: staticColors.purpleThree,
    'button-text': staticColors.offWhite,
    'button-background': staticColors.purpleOne,
    'button-background-hover': staticColors.purpleFour,
    'button-background-hover-light': staticColors.purpleSix,
    'button-toggle': staticColors.purpleOne,
    shadow: staticColors.blackLayer,
    'toggle-switch-bg': staticColors.grayTwo,
    'toggle-switch-fg': staticColors.grayOne,
    'toggle-switch-fg-active': staticColors.purpleTwo,
    'toggle-switch-bg-active': staticColors.grayOne,
    'sticky-heading': staticColors.blackLayer,
    'fade-fallback': 'rgba(18,13,23,.5)',
    fade: 'linear-gradient(90deg, rgba(18,13,23,.25) 0%, rgba(18,13,23,0.75) 20%, rgba(18,13,23,1) 100%)',
    'note-text': staticColors.purpleThree,
    'note-bg': staticColors.purpleTwo,
  },
  light: {
    primary: staticColors.purpleTwo,
    text: staticColors.black,
    'text-alternate': staticColors.purpleOne,
    background: staticColors.offWhite,
    card: staticColors.grayFour,
    'card-transparent': staticColors.grayFive,
    'background-transparent': staticColors.offWhiteLayer,
    'header-transparent': staticColors.graySix,
    focus: staticColors.grayOne,
    outline: staticColors.black,
    border: staticColors.grayOne,
    'button-text': staticColors.offWhite,
    'button-background': staticColors.purpleTwo,
    'button-background-hover': staticColors.purpleFive,
    'button-background-hover-light': staticColors.purpleSeven,
    'button-toggle': staticColors.purpleThree,
    shadow: staticColors.offWhiteLayer,
    'toggle-switch-bg': staticColors.grayTwo,
    'toggle-switch-fg': staticColors.grayOne,
    'toggle-switch-fg-active': staticColors.purpleTwo,
    'toggle-switch-bg-active': staticColors.purpleThree,
    'sticky-heading': staticColors.offWhiteLayer,
    'fade-fallback': 'rgba(255,255,255,.7)',
    fade: 'linear-gradient(90deg, rgba(255,255,255,.25) 0%, rgba(255,255,255,0.75) 20%, rgba(255,255,255,1) 100%)',
    'note-text': staticColors.purpleTwo,
    'note-bg': staticColors.purpleThree,
  },
}

const fonts = {
  primary: `'Poppins', sans-serif`,
  secondary: `'Poppins', sans-serif`,
  weights: {
    normal: 400,
    semibold: 600,
    bold: 900,
  },
  sizes: {
    base: `1.8rem`,
  },
}

const layers = {
  teir0: -1,
  teir1: 0,
  teir2: 250,
  teir3: 500,
  teir4: 750,
  teir5: 1000,
  teir6: 9999,
}

const sizes = {
  xs: 480,
  sm: 768,
  md: 1024,
  lg: 1200,
  xl: 1400,
  xxl: 1936, // this is a weird number
}

const theme = {
  staticColors,
  colors,
  fonts,
  layers,
  sizes,
}

const themeModeKey = 'themeMode'
const initialThemeModeKeyCssProp = '--initial-theme-mode'

export { colors, fonts, layers, sizes, theme, themeModeKey, initialThemeModeKeyCssProp }
