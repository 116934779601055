import 'sanitize.css'
import { createGlobalStyle, css } from 'styled-components'
import { theme } from '../helpers/constants'
import './fonts.css'

const {
  colors: { dark },
  staticColors: { offWhite, purpleTwo },
  sizes: { sm, lg },
} = theme

const GlobalStyles = createGlobalStyle`${css`
  :root {
    --color-primary: ${dark.primary};
    --color-text: ${dark.text};
    --color-text-alternate: ${dark['text-alternate']};
    --color-background: ${dark.background};
    --color-background-transparent: ${dark['background-transparent']};
    --color-header-transparent: ${dark['header-transparent']};
    --color-sticky-heading: ${dark['sticky-heading']};
    --color-border: ${dark.border};
    --color-button-text: ${dark['button-text']};
    --color-button-background: ${dark['button-background']};
    --color-button-background-hover: ${dark['button-background-hover']};
    --color-button-background-hover: ${dark['button-background-hover-light']};
    --color-button-toggle: ${dark['button-toggle']};
    --color-focus: ${dark.focus};
    --color-outline: ${dark.outline};
    --color-shadow: ${dark.shadow};
    --color-transition: color 0.3s ease-in-out, background 0.3s ease-in-out;
    --color-toggle-switch-bg: ${dark['toggle-switch-bg']};
    --color-toggle-switch-fg: ${dark['toggle-switch-fg']};
    --color-toggle-switch-bg-active: ${dark['toggle-switch-bg-active']};
    --color-toggle-switch-fg-active: ${dark['toggle-switch-fg-active']};
    --color-fade-fallback: ${dark['fade-fallback']};
    --color-fade: ${dark['fade']};
    --color-note-text: ${dark['note-text']};
    --color-note-bg: ${dark['note-bg']};
    --font-primary: 'Poppins', sans-serif;
    --font-secondary: var(--font-primary);
    --font-bold-weight: 900;
    --font-semibold-weight: 600;
    --font-normal-weight: 400;
    --font-size-base: 1.8rem;
    --layer-teir-0: -1;
    --layer-teir-1: 1;
    --layer-teir-2: 250;
    --layer-teir-3: 500;
    --layer-teir-4: 750;
    --layer-teir-5: 1000;
    --layer-teir-6: 9999;
    --layer-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    --layer-shadow-raised: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    --size-max: 2000px;
    --size-header-height: 82px;
    --size-border-radius: 3rem;
    --size-modal-offset: calc(50% - var(--gutter));
    --transition-layer: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    --gutter: var(--font-size-base);
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    @media all and (min-width: ${lg}px) {
      --font-size-base: 2.4rem;
    }
  }
  :where(h1, h2, h3, h4, h5, h6) {
    font-family: var(--font-primary);
    font-weight: var(--font-bold-weight);
    text-transform: lowercase;
    letter-spacing: normal;
  }
  ::selection {
    color: ${offWhite};
    background-color: ${purpleTwo};
  }
  :focus:not(button) {
    outline: var(--color-outline) auto 5px;
    border-radius: 1rem;
  }
  html {
    font-size: 62.5%;
  }
  body {
    background: var(--color-background);
    color: var(--color-text);
    font-family: var(--font-secondary);
    font-weight: var(--font-normal-weight);
    font-size: var(--font-size-base);
    min-height: 100vh;
    letter-spacing: 0.5px;
    position: relative;
  }
  h1 {
    font-size: 6rem;
    line-height: 1.2;
    margin: 0;
  }
  h2 {
    font-size: 3rem;
    @media all and (min-width: ${sm}px) {
      font-size: 4rem;
    }
  }
  h3 {
    font-size: 2.8rem;
    @media all and (min-width: ${sm}px) {
      font-size: 3.4rem;
    }
  }
  h4 {
    font-size: 2.6rem;
    @media all and (min-width: ${sm}px) {
      font-size: 3rem;
    }
  }
  a {
    color: var(--color-primary);
    transition: var(--color-transition);
    &:focus,
    &:hover {
      outline: none;
      text-decoration: none;
    }
  }
  a:not(.link-button):not(.icon a):not(nav a):not(.logo a):not(.button) {
    &:focus {
      background-color: var(--color-primary);
      color: var(--color-text);
      border-radius: 0;
    }
  }
  a.link-button {
    &--primary {
      &:focus {
        span {
          outline: 3px solid var(--color-outline);
        }
      }
    }
    &--not-important {
      &:focus {
        span {
          background-color: var(--color-button-background-hover-light);
        }
      }
    }
    &--important {
      &:focus {
        span {
          background-color: var(--color-button-background-hover);
        }
      }
    }
  }
  button {
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    color: var(--color-text);
  }
  input {
    width: 100%;
    font-size: var(--font-size-base);
    font-family: var(--font-secondary);
    padding: calc(var(--gutter) / 2) var(--gutter);
    margin: calc(var(--gutter) / 2) 0 var(--gutter) 0;
    color: var(--color-text);
    background-color: var(--color-background);
    border: 1px solid;
    &:focus {
      border-radius: 0;
    }
  }
  textarea {
    width: 100%;
    font-size: var(--font-size-base);
    font-family: var(--font-secondary);
    padding: calc(var(--gutter) / 2) var(--gutter);
    margin: calc(var(--gutter) / 2) 0 var(--gutter) 0;
    color: var(--color-text);
    background-color: var(--color-background);
    border: 1px solid;
    min-height: 200px;
    &:focus {
      border-radius: 0;
    }
  }
  label {
    font-family: var(--font-primary);
  }
  ul {
    display: flex;
    flex-flow: column wrap;
    gap: var(--gutter);
  }
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  .element {
    &__is-locked {
      overflow: hidden;
    }
  }
  .float {
    animation: float 6s ease-in-out infinite;
  }
  .rock-and-float {
    animation: rockAndFloat 6s ease-in-out infinite;
  }
  .animated-path {
    stroke-dasharray: 1;
    stroke-dashoffset: 1;
    animation: drawSVG 3s linear forwards;
  }
  .fill-svg {
    fill: none;
    animation: fillSVG 0.6s linear forwards 2s;
  }
  @keyframes float {
    0% {
      transform: translatey(0rem);
    }
    50% {
      transform: translatey(-2rem);
    }
    100% {
      transform: translatey(0rem);
    }
  }
  @keyframes rockAndFloat {
    0% {
      transform: translatey(0rem) rotate(0deg);
    }
    25% {
      transform: rotate(-1deg);
    }
    50% {
      transform: translatey(-2rem);
    }
    75% {
      transform: rotate(1deg);
    }
    100% {
      transform: translatey(0rem) rotate(0deg);
    }
  }
  @keyframes drawSVG {
    0% {
      stroke-dashoffset: 1;
    }
    50% {
      stroke-dashoffset: 0;
    }
    0% {
      stroke-dashoffset: 1;
    }
  }
  @keyframes fillSVG {
    0% {
      fill: transparent;
    }
    100% {
      fill: currentColor;
    }
  }
`}`

export default GlobalStyles
