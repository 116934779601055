/* eslint-disable react/jsx-no-useless-fragment */
import { Link } from 'gatsby'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { closeTakeoversEvent, enableFocusTrapping, lockDocument } from '../helpers'
import { sizes } from '../helpers/constants'
import { StyledLayout } from '../styles/Layout'
import Button from './Button'
import CloseIcon from './icons/Close'
import MenuIcon from './icons/Menu'

const StyledNav = styled(StyledLayout)`
  --gutter: 0;
  font-family: var(--font-primary);
  font-weight: var(--font-bold-weight);
  font-size: 2.8rem;
  text-transform: lowercase;
  justify-content: center;
  .menu__icon,
  .close__icon {
    @media all and (min-width: ${sizes.md}px) {
      display: none;
    }
  }
`

type StyledUlProps = {
  isMenuOpen: boolean
}

const StyledUl = styled(StyledLayout)<StyledUlProps>`
  --gutter: 0;
  gap: 0;
  inset: 0;
  display: ${({ isMenuOpen }) => (isMenuOpen ? 'block' : 'none')};
  margin: var(--gutter);
  padding: 2rem 0;
  width: 100%;
  top: var(--size-header-height);
  min-height: calc(100vh - var(--size-header-height));
  position: absolute;
  background-color: var(--color-background);
  overflow-y: auto;
  li {
    padding: var(--gutter);
    margin: 2rem 0;
    text-align: center;
    @media all and (min-width: ${sizes.md}px) {
      text-align: initial;
      padding: 0 1rem;
      margin: 0;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }
  a {
    text-decoration: none;
    color: currentColor;
    &:visited {
      text-decoration: none;
      color: currentColor;
    }
    &:hover,
    &:focus,
    &:focus-within,
    &:focus-visible {
      color: var(--color-primary);
      outline: none;
    }
    &[aria-current='page'] {
      text-decoration: underline;
      text-decoration-color: var(--color-primary);
    }
  }
  @media all and (min-width: ${sizes.md}px) {
    display: flex;
    position: static;
    min-height: initial;
    padding: 0;
  }
`

type listItemProps =
  | {
      text: string
      href?: string
      button?: never
      buttonStyle?: never
      onClick?: (x?: any) => any
      externalLink?: boolean
      openInNewTab?: boolean
      ariaLabel?: string
      title?: string
    }
  | {
      text: string
      href?: never
      button: true
      buttonStyle: 'primary' | 'secondary'
      onClick: (x?: any) => any
      externalLink?: never
      openInNewTab?: never
      ariaLabel: string
      title: string
    }

const buildListItem = function (listItem: listItemProps) {
  const { text, href, button, buttonStyle, onClick, externalLink, openInNewTab, ariaLabel, title } = listItem
  if (href) {
    return (
      <>
        {!externalLink && (
          <Link
            to={href}
            target={openInNewTab ? '_blank' : null}
            onClick={() => document.dispatchEvent(closeTakeoversEvent())}
          >
            {text}
          </Link>
        )}
        {externalLink && (
          <a
            href={href}
            target={openInNewTab ? '_blank' : null}
            rel='noopener noreferrer'
            onClick={() => document.dispatchEvent(closeTakeoversEvent())}
          >
            {text}
          </a>
        )}
      </>
    )
  }
  if (button) {
    return (
      <Button
        type='button'
        ariaLabel={ariaLabel}
        buttonStyle={buttonStyle}
        title={title}
        onClick={() => {
          document.dispatchEvent(closeTakeoversEvent())
          onClick()
        }}
      >
        {text}
      </Button>
    )
  }
}

type NavWithMobileMenuProps = {
  list: listItemProps[]
  className?: string
}

export default function NavWithMobileMenu({ list, className }: NavWithMobileMenuProps) {
  const [isMenuOpen, setIsMenuOpen] = useState<StyledUlProps['isMenuOpen']>(false)
  const navRef = useRef(null)

  useEffect(() => {
    const closeMenu = () => setIsMenuOpen(false)

    document.addEventListener('closeTakeovers', closeMenu)
    return () => document.removeEventListener('closeTakeovers', closeMenu)
  })

  useEffect(() => {
    const enableFocusTrapLogic = function (e: KeyboardEvent) {
      enableFocusTrapping(e, navRef?.current, () => setIsMenuOpen(false))
    }

    if (isMenuOpen) {
      lockDocument(true)
      document.addEventListener('keydown', enableFocusTrapLogic)
    } else {
      lockDocument(false)
      document.removeEventListener('keydown', enableFocusTrapLogic)
    }

    return () => {
      document.removeEventListener('keydown', enableFocusTrapLogic)
    }
  }, [isMenuOpen])

  return (
    <>
      {list && list.length > 0 && (
        <StyledNav as='nav' className={className} ref={navRef}>
          <StyledUl as='ul' stack stackBelowSize='md' isMenuOpen={isMenuOpen}>
            {list.map((listItem, idx) => (
              <li key={`list__item--${idx}`}>{buildListItem(listItem)}</li>
            ))}
          </StyledUl>
          {isMenuOpen ? (
            <CloseIcon onClick={() => setIsMenuOpen(!isMenuOpen)} />
          ) : (
            <MenuIcon
              onClick={() => {
                document.dispatchEvent(closeTakeoversEvent())
                setIsMenuOpen(!isMenuOpen)
              }}
            />
          )}
        </StyledNav>
      )}
    </>
  )
}
