import React from 'react'
import { CgMenuRight as MenuIcon } from 'react-icons/cg'
import Icon from '../Icon'

type MenuProps = {
  onClick: (x: any) => any
}

export default function Menu({ onClick }: MenuProps) {
  return (
    <Icon
      className='menu__icon'
      ariaLabel='Click to open navigation menu.'
      title='Open navigation menu.'
      button
      onClick={onClick}
    >
      <MenuIcon />
    </Icon>
  )
}
