/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { enableFocusTrapping } from '../helpers'
import { layers, sizes } from '../helpers/constants'
import { useModal } from '../helpers/hooks'
import { StyledCard } from '../styles/Card'
import Close from './icons/Close'

const StyledModal = styled.div<{ size: 'sm' | 'lg' }>`
  position: fixed;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  z-index: var(--layer-teir-6);
  background-color: var(--color-background-transparent);
  &:focus {
    border-radius: 0;
  }
  .modal {
    max-height: calc(96vh - (var(--gutter) * 2));
    &__content {
      ${StyledCard}
      max-width: ${({ size }) => (size ? `${sizes[size]}px` : `${sizes.sm}px`)};
      position: relative;
      margin: var(--gutter);
      border: 2px solid var(--color-primary);
      overflow-y: auto;
      width: 100%;
      top: var(--size-modal-offset);
      left: var(--size-modal-offset);
      transform: translate(-50%, -50%);
      margin: var(--gutter);
      background-color: var(--color-background);
    }
    .title {
      position: sticky;
      top: 0;
      background: var(--color-background);
      z-index: ${layers.teir6};
      &__content {
        margin: 0;
        width: calc(100% - 30px);
        font-weight: bold;
        font-family: var(--font-primary);
        text-transform: lowercase;
      }
    }
  }
`

export default function Modal() {
  const { modalData, setModalData } = useModal()
  const modalRef = useRef(null)

  useEffect(() => {
    if (!modalData) return

    const enableFocusTrapLogic = function (e: KeyboardEvent) {
      enableFocusTrapping(e, modalRef?.current, () => setModalData(null))
    }

    document.addEventListener('keydown', enableFocusTrapLogic)
    return () => {
      document.removeEventListener('keydown', enableFocusTrapLogic)
    }
  }, [modalData, setModalData])

  return (
    <>
      {modalData?.content ? (
        <StyledModal ref={modalRef} tabIndex={-1} role='dialog' size={modalData.size}>
          <section className='modal modal__content' role='document'>
            <div className='title'>
              <h3 className='title__content'>{modalData.title}</h3>
              <Close onClick={() => setModalData(null)} />
            </div>
            <div className='content'>{modalData.content}</div>
          </section>
        </StyledModal>
      ) : null}
    </>
  )
}
