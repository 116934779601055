import React, { useContext } from 'react'
import styled from 'styled-components'
import { AnimationContext } from '../context/AnimationContext'
import { SoundContext } from '../context/SoundContext'
import { ThemeContext } from '../context/ThemeContext'
import { sizes } from '../helpers/constants'
import ToggleSwitch from './ToggleSwitch'

const StyledSetting = styled.div`
  padding: var(--gutter) 0;
  border-bottom: 1px solid var(--color-button-toggle);
  &:first-child {
    border-top: 1px solid var(--color-button-toggle);
  }
  &:last-child {
    border-bottom: 0;
  }
  h4 {
    margin: 1rem 0 0 0;
    font-size: 2.6rem;
  }
  .setting__row {
    display: grid;
    flex-flow: column wrap;
    > label {
      justify-self: center;
    }
    @media all and (min-width: ${sizes.xs}px) {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
      > p {
        width: 80%;
      }
    }
  }
`

export default function SettingsPanel() {
  const { currentThemeMode, setThemeMode } = useContext(ThemeContext)
  const { allowSound, setAllowSound } = useContext(SoundContext)
  const { allowAnimation, setAllowAnimation } = useContext(AnimationContext)

  return (
    <section>
      <StyledSetting>
        <h4>Toggle Theme:</h4>
        <div className='setting__row'>
          <p>
            Current Theme: <b>{currentThemeMode === 'dark' ? 'Dark' : 'Light'} mode</b>.
          </p>
          <ToggleSwitch
            checked={currentThemeMode === 'dark'}
            onClick={() => setThemeMode(currentThemeMode === 'dark' ? 'light' : 'dark')}
          />
        </div>
      </StyledSetting>
      <StyledSetting>
        <h4>Toggle Sound:</h4>
        <div className='setting__row'>
          <p>
            Current Sound Status: <b>Sound {allowSound ? 'On' : 'Off'}</b>
          </p>
          <ToggleSwitch
            checked={allowSound}
            onClick={() => {
              setAllowSound(!allowSound)
            }}
          />
        </div>
      </StyledSetting>
      <StyledSetting>
        <h4>Toggle Animations:</h4>
        <div className='setting__row'>
          <p>
            Current Animation Status: <b>Animations {allowAnimation ? 'On' : 'Off'}</b>
          </p>
          <ToggleSwitch
            checked={allowAnimation}
            onClick={() => {
              setAllowAnimation(!allowAnimation)
            }}
          />
        </div>
      </StyledSetting>
    </section>
  )
}
