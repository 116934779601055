import { css } from 'styled-components'
import { sizes } from '../helpers/constants'

export const StyledCard = css`
  max-width: ${sizes.sm}px;
  border: 2px solid var(--color-border);
  background-color: var(--color-card);
  border-radius: var(--size-border-radius);
  display: flex;
  flex-direction: column;
  box-shadow: var(--layer-shadow);
  transition: var(--transition-layer);
  &:hover {
    box-shadow: var(--layer-shadow-raised);
  }
  .title,
  .content {
    padding: var(--gutter);
    > p {
      margin-top: 0;
    }
  }
  .title {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--color-text);
  }
`
