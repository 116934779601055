import React, { createContext, useEffect, useState } from 'react'

const getInitialSoundPreference = function () {
  if (typeof window !== 'undefined' && window?.localStorage?.getItem('allowSound') === 'false') {
    return false
  } else {
    return true
  }
}

const initialContext: {
  allowSound?: boolean
  setAllowSound: React.Dispatch<React.SetStateAction<boolean>>
} = {
  allowSound: getInitialSoundPreference(),
  setAllowSound: null,
}

const SoundContext = createContext(initialContext)

type SoundProviderProps = {
  children: React.ReactNode
}

function SoundProvider({ children }: SoundProviderProps) {
  const [allowSound, setAllowSound] = useState<boolean>(initialContext.allowSound)

  useEffect(() => {
    localStorage?.setItem('allowSound', allowSound.toString())
  }, [allowSound])

  return <SoundContext.Provider value={{ allowSound, setAllowSound }}>{children}</SoundContext.Provider>
}

export { SoundContext, SoundProvider }
