import React from 'react'
import { IoMdCloseCircle as CloseIcon } from 'react-icons/io'
import Icon from '../Icon'

type CloseProps = {
  onClick: (x: any) => any
}

export default function Close({ onClick }: CloseProps) {
  return (
    <Icon className='close__icon' ariaLabel='Click to close.' title='Close.' button onClick={onClick}>
      <CloseIcon />
    </Icon>
  )
}
