import React, { createContext, useEffect, useMemo, useState } from 'react'
import { initialThemeModeKeyCssProp, theme, themeModeKey } from '../helpers/constants'

type ThemeModeOptions = 'light' | 'dark'

type ThemeContextProps = {
  currentThemeMode: ThemeModeOptions
  setThemeMode: (value?: ThemeModeOptions) => void
}

const initialContext: ThemeContextProps = {
  currentThemeMode: null,
  setThemeMode: null,
}

const ThemeContext = createContext(initialContext)

type ThemeProviderProps = {
  children: React.ReactNode
}

function ThemeProvider({ children }: ThemeProviderProps) {
  const [currentThemeMode, setCurrentThemeMode] = useState(undefined)
  useEffect(() => {
    const root = window.document.documentElement
    const initialThemeMode = root.style.getPropertyValue(initialThemeModeKeyCssProp)
    if (initialThemeMode === 'light' || initialThemeMode === 'dark') {
      setCurrentThemeMode(initialThemeMode)
    }
  }, [])

  const ThemeContextProviderProps = useMemo(() => {
    const setThemeMode = (value: ThemeModeOptions = 'dark') => {
      const root = window.document.documentElement

      localStorage.setItem(themeModeKey, value)

      Object.entries(theme.colors[value]).forEach(([name, colorByTheme]) => {
        const cssVarName = `--color-${name}`

        root.style.setProperty(cssVarName, colorByTheme)
      })

      setCurrentThemeMode(value)
    }

    return {
      currentThemeMode,
      setThemeMode,
    }
  }, [currentThemeMode, setCurrentThemeMode])

  return <ThemeContext.Provider value={ThemeContextProviderProps}>{children}</ThemeContext.Provider>
}

export { ThemeContext, ThemeProvider }
