import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { sizes, theme } from '../helpers/constants'
import { StyledLayout } from '../styles/Layout'
import Logo from './Logo'

const StyledFooter = styled(StyledLayout)`
  font-size: 1.2rem;
  max-width: 100%;
  text-align: center;
  > div {
    width: 100%;
  }
  ul {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: space-between;
    list-style-type: none;
    padding: 0;
    margin: 0 auto;
    gap: calc(var(--gutter) / 2);
    /* min-width: 32rem; */
    max-width: 50rem;
    @media all and (min-width: ${sizes.xs}px) {
      flex-flow: row wrap;
    }
    @media all and (min-width: ${sizes.sm}px) {
      gap: var(--gutter);
    }
  }
  .copyright {
    text-align: center;
    font-size: 1.4rem;
  }
  .logo {
    margin: auto;
  }
`

export default function Footer() {
  const currentYear = new Date().getFullYear()
  return (
    <StyledFooter as='footer'>
      <div>
        <ul>
          <li>
            <Link to='/terms-and-conditions' title='Click to view the terms and conditions of this site.'>
              Terms
            </Link>
          </li>
          <li>
            <Link to='/privacy-policy' title='Click to view the privacy policy of this site.'>
              Privacy Policy
            </Link>
          </li>
          <li>
            <a
              href='https://www.youtube.com/@AdamAnSubtractM'
              title='Click to view my YouTube channel.'
              target={'_blank'}
            >
              My YouTube
            </a>
          </li>
          <li>
            <a
              href='https://twitter.com/AdamAnSubtractM'
              title="Click to view my Twitter (I don't tweet much)."
              target={'_blank'}
            >
              My Twitter <span style={{ whiteSpace: 'nowrap' }}>(I rarely tweet)</span>
            </a>
          </li>
        </ul>
        <p className='copyright'>
          &#169; {currentYear} Adam Knee. Artwork&nbsp;by&nbsp;yours&nbsp;truly&nbsp;❤️. All&nbsp;Rights&nbsp;Reserved.
        </p>
        <Logo svgFill={theme.staticColors.grayTwo} maxWidth={`15rem`} />
      </div>
    </StyledFooter>
  )
}
