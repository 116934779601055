import React, { createContext, useEffect, useState } from 'react'

const getInitialAnimationPreference = function () {
  if (typeof window !== 'undefined' && window?.localStorage?.getItem('allowAnimation') === 'false') {
    return false
  } else {
    return true
  }
}

const initialContext: {
  allowAnimation?: boolean
  setAllowAnimation: React.Dispatch<React.SetStateAction<boolean>>
} = {
  allowAnimation: getInitialAnimationPreference(),
  setAllowAnimation: null,
}

const AnimationContext = createContext(initialContext)

type AnimationProviderProps = {
  children: React.ReactNode
}

function AnimationProvider({ children }: AnimationProviderProps) {
  const [allowAnimation, setAllowAnimation] = useState<boolean>(initialContext.allowAnimation)

  useEffect(() => {
    localStorage?.setItem('allowAnimation', allowAnimation.toString())
  }, [allowAnimation])

  return <AnimationContext.Provider value={{ allowAnimation, setAllowAnimation }}>{children}</AnimationContext.Provider>
}

export { AnimationContext, AnimationProvider }
