import React from 'react'
import styled from 'styled-components'
import { StyledLayout } from '../styles/Layout'
import Footer from './Footer'
import Header from './Header'
import Modal from './Modal'

type PageProps = {
  children: React.ReactNode
}

const StyledMain = styled(StyledLayout)`
  min-height: calc(100vh - var(--size-header-height));
  align-items: stretch;
  margin: auto;
  justify-content: center;
`

export default function Page({ children }: PageProps) {
  return (
    <>
      <Header />
      <StyledMain as='main'>{children}</StyledMain>
      <Footer />
      <Modal />
    </>
  )
}
