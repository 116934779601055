import styled, { css } from 'styled-components'
import { sizes } from '../helpers/constants'

type StyledLayoutProps =
  | {
      stack?: false
      stackBelowSize?: never
      justifyContent?: 'center' | 'start' | 'end' | 'flex-start' | 'flex-end' | 'left' | 'right'
      alignContent?: 'center' | 'start' | 'end' | 'stretch'
    }
  | {
      stack: true
      stackBelowSize: Constants['size']
      justifyContent?: 'center' | 'start' | 'end' | 'flex-start' | 'flex-end' | 'left' | 'right'
      alignContent?: 'center' | 'start' | 'end' | 'stretch'
    }

const StyledLayout = styled.div<StyledLayoutProps>`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};
  align-items: ${({ alignContent }) => alignContent || 'space-between'};
  padding: var(--gutter);
  max-width: var(--size-max);
  flex-flow: ${({ stack }) => (stack ? 'column nowrap' : 'row nowrap')};
  gap: ${({ stack }) => (stack ? 'initial' : 'var(--gutter)')};
  @media all and (min-width: ${sizes.md}px) {
    padding: var(--gutter) calc(var(--gutter) * 2);
    gap: var(--gutter);
  }
  ${({ stack, stackBelowSize, justifyContent }: StyledLayoutProps) =>
    stack &&
    stackBelowSize &&
    css`
      @media all and (min-width: ${sizes[stackBelowSize]}px) {
        flex-flow: row nowrap;
        justify-content: ${justifyContent || 'space-between'};
        gap: var(--gutter);
      }
    `}
`

const StyledContent = styled(StyledLayout)<StyledLayoutProps>`
  padding: 0;
  > div {
    width: 100%;
  }
  .call-to-action {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ${({ stack, stackBelowSize }: StyledLayoutProps) =>
    stack &&
    stackBelowSize &&
    css`
      @media all and (min-width: ${sizes[stackBelowSize]}px) {
        .call-to-action {
          justify-content: start;
        }
      }
    `}
`

export { StyledLayout, StyledContent }
