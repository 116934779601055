export const isClient = typeof window !== 'undefined'

export const closeTakeoversEvent = () => (isClient ? new Event('closeTakeovers') : null)

export const truncate = (text: string, maxLength: number) =>
  text.length > maxLength ? `${text.substring(0, maxLength)}...` : text

export const lockDocument = (shouldLock = false) => {
  const queryString = 'element__is-locked'
  const htmlEl = document.querySelector('html')

  if (shouldLock) {
    htmlEl.classList.add(queryString)
  } else {
    htmlEl.classList.remove(queryString)
  }
}

export const enableFocusTrapping = function (
  e: KeyboardEvent,
  focusTrappedContainer: Element,
  escapeCallback: () => any
) {
  if (!e || !focusTrappedContainer) return
  const focusableElements =
    'button, [href], input:not(.non-focusable), select, textarea, [tabindex]:not([tabindex="-1"])'
  const focusableContent = focusTrappedContainer.querySelectorAll(focusableElements)
  const focusableContentLength = focusableContent?.length
  if (!focusableContentLength) return
  const firstFocusableElement = focusableContent[0]
  const lastFocusableElement = focusableContent[focusableContentLength - 1]
  const isTabPressed = e.key === 'Tab'
  const isEscapePressed = e.key === 'Escape'
  if (!isTabPressed && !isEscapePressed) return
  if (isEscapePressed) return escapeCallback()
  if (e.shiftKey) {
    if (document?.activeElement === firstFocusableElement) {
      //@ts-ignore
      lastFocusableElement?.focus()
      e.preventDefault()
    }
  } else if (document?.activeElement === lastFocusableElement) {
    //@ts-ignore
    firstFocusableElement?.focus()
    e.preventDefault()
  }
  if (!Array.from(focusableContent).includes(document.activeElement)) {
    //@ts-ignore
    firstFocusableElement?.focus()
  }
}
