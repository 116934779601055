import React from 'react'
import styled from 'styled-components'
import { sizes } from '../helpers/constants'
import { StyledLayout } from '../styles/Layout'
import ControlsTray from './ControlsTray'
import Logo from './Logo'
import MainNav from './MainNav'

const { md } = sizes

const StyledHeader = styled(StyledLayout)`
  width: 100%;
  max-width: 100%;
  position: sticky;
  top: 0;
  z-index: var(--layer-teir-5);
  background: var(--color-background);
  height: var(--size-header-height);
  gap: initial;
  .controls {
    margin: 0 var(--gutter);
    @media all and (min-width: ${md}px) {
      margin: initial;
    }
  }
  .logo__svg {
    width: var(--size-logo-width);
  }
  .logo--main {
    flex-grow: 2;
    @media all and (min-width: ${md}px) {
      flex-grow: initial;
    }
  }
  .nav--main {
    order: 2;
    @media all and (min-width: ${md}px) {
      order: initial;
    }
  }
`

export default function Header() {
  return (
    <StyledHeader as='header' alignContent='center'>
      <Logo />
      <MainNav />
      <ControlsTray />
    </StyledHeader>
  )
}
