/* eslint-disable react/jsx-no-useless-fragment */
import { Link } from 'gatsby'
import React, { useContext } from 'react'
import styled from 'styled-components'
import useSound from 'use-sound'
import iconClickSound from '../assets/sounds/bite.mp3'
import { SoundContext } from '../context/SoundContext'

type StyledButtonProps = {
  buttonStyle: 'primary' | 'secondary'
  removeImportance: boolean
}

const StyledButton = styled.button<StyledButtonProps>`
  --gutter: 1.6rem;
  --button-radius: 3.2rem;
  -webkit-appearance: button;
  appearance: button;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  width: auto;
  margin: var(--gutter) 0.6rem;
  padding: 1.4rem 2rem;
  color: ${({ removeImportance }) =>
    removeImportance ? 'var(--color-button-background)' : 'var(--color-button-text)'};
  border: 1px solid var(--color-button-background);
  background-color: ${({ removeImportance }) => (removeImportance ? 'transparent' : 'var(--color-button-background)')};
  font-family: var(--font-primary);
  font-size: ${({ buttonStyle }) => (buttonStyle === 'primary' ? `1.6rem` : `1.4rem`)};
  font-weight: var(--font-normal-weight);
  border-radius: ${({ buttonStyle }) => (buttonStyle === 'primary' ? `3.2rem` : `4rem`)};
  &:focus {
    border-radius: ${({ buttonStyle }) => (buttonStyle === 'primary' ? `var(--button-radius)` : `4rem`)};
    outline: 3px solid var(--color-outline);
    background-color: ${({ removeImportance }) =>
      removeImportance ? 'var(--color-button-background-hover-light)' : 'var(--color-button-background-hover)'};
  }
  &:hover {
    background-color: ${({ removeImportance }) =>
      removeImportance ? 'var(--color-button-background-hover-light)' : 'var(--color-button-background-hover)'};
  }
  &::selection {
    color: var(--color-button-background);
    background-color: var(--color-button-text);
  }
  a {
    text-decoration: none;
    color: currentColor;
    font-weight: inherit;
  }
  .icon {
    margin: 0 0.25rem;
  }
  .icon svg {
    color: currentColor;
    &:hover,
    &:focus {
      color: currentColor;
    }
  }
  .icon path {
    &:hover,
    &:focus {
      color: currentColor;
    }
  }
  &:disabled,
  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
  }
`

type ButtonProps =
  | {
      children: React.ReactNode
      type: 'button' | 'submit' | 'reset'
      buttonStyle: StyledButtonProps['buttonStyle']
      removeImportance?: StyledButtonProps['removeImportance']
      onClick: (x?: any) => any
      href?: never
      externalLink?: never
      openInNewTab?: never
      title: string
      ariaLabel: string
      disabled?: boolean
    }
  | {
      children: React.ReactNode
      type?: never
      buttonStyle: StyledButtonProps['buttonStyle']
      removeImportance?: StyledButtonProps['removeImportance']
      onClick?: never
      href: string
      externalLink?: boolean
      openInNewTab?: boolean
      title: string
      ariaLabel: string
      disabled?: boolean
      download?: string
    }

export default function Button({
  children,
  type,
  buttonStyle,
  removeImportance,
  onClick,
  href,
  externalLink,
  openInNewTab,
  title,
  ariaLabel,
  disabled,
}: ButtonProps) {
  const { allowSound } = useContext(SoundContext)
  const [play] = useSound(iconClickSound)
  if (href) {
    return (
      <>
        {externalLink ? (
          <StyledButton
            as='a'
            href={href}
            buttonStyle={buttonStyle}
            removeImportance={removeImportance}
            rel='noopener noreferrer'
            title={title}
            aria-label={ariaLabel}
            target={openInNewTab ? '_blank' : null}
            className={`button ${disabled ? 'disabled' : ''}`}
            onClick={() => {
              if (allowSound) {
                play()
              }
            }}
          >
            {children}
          </StyledButton>
        ) : (
          <Link
            to={href}
            title={title}
            aria-label={ariaLabel}
            className={`link-button link-button--${buttonStyle} link-button--${
              removeImportance ? 'non-important' : 'important'
            } ${disabled ? 'disabled' : ''}`}
            onClick={() => {
              if (allowSound) {
                play()
              }
            }}
          >
            <StyledButton as='span' buttonStyle={buttonStyle} removeImportance={removeImportance}>
              {children}
            </StyledButton>
          </Link>
        )}
      </>
    )
  }
  return (
    <StyledButton
      type={type}
      buttonStyle={buttonStyle}
      removeImportance={removeImportance}
      onClick={() => {
        if (allowSound) {
          play()
        }
        if (onClick) {
          onClick()
        }
      }}
      title={title}
      aria-label={ariaLabel}
      disabled={disabled}
    >
      {children}
    </StyledButton>
  )
}
