import React from 'react'
import { IoLogoGithub as GithubIcon } from 'react-icons/io'
import Icon from '../Icon'

export default function Github() {
  const adaHelperText = `Select to view Adam Knee's Github.`

  return (
    <Icon
      href='https://github.com/AdamAnSubtractM'
      externalLink
      openInNewTab
      ariaLabel={adaHelperText}
      title={adaHelperText}
    >
      <GithubIcon />
    </Icon>
  )
}
