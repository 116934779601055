import { Link } from 'gatsby'
import React, { useContext } from 'react'
import styled from 'styled-components'
import useSound from 'use-sound'
import iconClickSound from '../assets/sounds/bite.mp3'
import { SoundContext } from '../context/SoundContext'

type StyledIconProps = {
  small?: boolean
}

type IconProps = {
  children: React.ReactNode
  href?: string
  button?: boolean
  onClick?: (x?: any) => any
  externalLink?: boolean
  openInNewTab?: boolean
  ariaLabel: string
  title: string
  className?: string
  small?: StyledIconProps['small']
}

const StyledIcon = styled.span<StyledIconProps>`
  font-size: ${props => (props.small ? '1.5rem' : '3rem')};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: currentColor;
  cursor: pointer;
  position: relative;
  * {
    color: inherit;
    font-size: inherit;
    display: inline-flex;
    cursor: inherit;
    &:focus {
      outline: none;
    }
    &:hover {
      color: var(--color-primary);
      outline: none;
    }
  }
  button {
    appearance: none;
  }
  button,
  a {
    &:focus {
      outline: none;
      &::before {
        content: '';
        background-color: var(--color-focus);
        border-radius: 50%;
        position: absolute;
        top: -0.5rem;
        bottom: -0.5rem;
        right: -0.5rem;
        left: -0.5rem;
        z-index: var(--layer-teir-0);
      }
    }
  }
`

export default function Icon({
  children,
  href,
  button,
  onClick,
  externalLink,
  openInNewTab,
  ariaLabel,
  title,
  className,
  small,
}: IconProps) {
  const [play] = useSound(iconClickSound)
  const { allowSound } = useContext(SoundContext)

  if (href) {
    return (
      <StyledIcon
        aria-label={ariaLabel}
        title={title}
        className={className ? `icon ${className}` : `icon`}
        small={small}
      >
        {!externalLink && (
          <Link to={href} target={openInNewTab ? '_blank' : null}>
            {children}
          </Link>
        )}
        {externalLink && (
          <a
            href={href}
            target={openInNewTab ? '_blank' : null}
            rel='noopener noreferrer'
            aria-label={ariaLabel}
            title={title}
          >
            {children}
          </a>
        )}
      </StyledIcon>
    )
  }
  if (button) {
    return (
      <StyledIcon
        role='button'
        onClick={() => {
          if (allowSound) {
            play()
          }
          onClick()
        }}
        aria-label={ariaLabel}
        title={title}
        className={className ? `icon ${className}` : `icon`}
        small={small}
      >
        <button type='button'>{children}</button>
      </StyledIcon>
    )
  }
  if (!button && !href) {
    return (
      <StyledIcon
        aria-label={ariaLabel}
        title={title}
        className={className ? `icon ${className}` : `icon`}
        small={small}
      >
        {children}
      </StyledIcon>
    )
  }
}
