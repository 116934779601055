import React from 'react'
import { IoMdSettings as SettingsIcon } from 'react-icons/io'
import styled from 'styled-components'
import { useModal } from '../../helpers/hooks'
import Icon from '../Icon'
import SettingsPanel from '../SettingsPanel'

const StyledInstructions = styled.p`
  font-family: var(--font-primary);
`

export default function Settings() {
  const { isModalOpen, setModalData } = useModal()
  const adaHelperText = `Select to open settings modal.`

  return (
    <Icon
      button
      ariaLabel={adaHelperText}
      title={adaHelperText}
      onClick={() => {
        setModalData(
          !isModalOpen
            ? {
                title: 'Settings Controls',
                content: (
                  <>
                    <StyledInstructions>
                      Use the toggle switches below to manage your settings for this site.
                    </StyledInstructions>
                    <SettingsPanel />
                  </>
                ),
              }
            : null
        )
      }}
    >
      <SettingsIcon />
    </Icon>
  )
}
