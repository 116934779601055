/* eslint-disable react/jsx-filename-extension */
import React from 'react'
import Page from './src/components/Page'
import { AnimationProvider } from './src/context/AnimationContext'
import { SoundProvider } from './src/context/SoundContext'
import { ModalProvider } from './src/context/ModalContext'
import { ThemeProvider } from './src/context/ThemeContext'
import GlobalStyles from './src/styles/GlobalStyles'

export function wrapRootElement({ element }) {
  return (
    <AnimationProvider>
      <SoundProvider>
        <ThemeProvider>{element}</ThemeProvider>
      </SoundProvider>
    </AnimationProvider>
  )
}

export function wrapPageElement({ element }) {
  return (
    <ModalProvider>
      <Page>
        <GlobalStyles />
        {element}
      </Page>
    </ModalProvider>
  )
}
