import React from 'react'
import styled from 'styled-components'
import { StyledLayout } from '../styles/Layout'
import GithubIcon from './icons/Github'
import SettingsIcon from './icons/Settings'
import ThemeToggle from './ThemeToggle'

const StyledControlsTray = styled(StyledLayout)`
  gap: 0;
  padding: 0;
  margin: 0;
  list-style-type: none;
  li {
    display: flex;
    margin: 0 0.5rem;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`

export default function ControlsTray() {
  return (
    <StyledControlsTray as='ul' className='controls'>
      <li>
        <ThemeToggle />
      </li>
      <li>
        <SettingsIcon />
      </li>
      <li>
        <GithubIcon />
      </li>
    </StyledControlsTray>
  )
}
