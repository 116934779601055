import { useContext, useEffect, useState } from 'react'
import { closeTakeoversEvent, lockDocument } from '..'
import { ModalContext } from '../../context/ModalContext'

export const useModal = function () {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { modalData, setModalData } = useContext(ModalContext)

  useEffect(() => {
    if (!isModalOpen) {
      document.dispatchEvent(closeTakeoversEvent())
      lockDocument(false)
    } else {
      lockDocument(true)
    }
  }, [isModalOpen])

  useEffect(() => {
    setIsModalOpen(Boolean(modalData))
  }, [modalData])

  return {
    isModalOpen,
    setIsModalOpen,
    modalData,
    setModalData,
  }
}
