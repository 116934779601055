import React, { useContext, useEffect, useRef } from 'react'
import styled from 'styled-components'
import useSound from 'use-sound'
import switchOff from '../assets/sounds/switch-off.mp3'
import switchOn from '../assets/sounds/switch-on.mp3'
import { SoundContext } from '../context/SoundContext'

type ToggleSwitchProps = {
  onClick: (x?: any) => any
  checked?: boolean
}

const StyledToggleSwitch = styled.label`
  --padding: 8px;
  --width: 80px;
  --height: calc((var(--width) / 2) + var(--padding));
  cursor: pointer;
  background: var(--color-toggle-switch-bg);
  padding: var(--padding);
  width: var(--width);
  height: var(--height);
  border-radius: calc(var(--height) / 2);
  input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }
  .switch {
    height: 100%;
    display: grid;
    grid-template-columns: 0fr 1fr 1fr;
    transition: 0.2s;
    //ICYMI, pseudo elements are treated as grid items
    &::after {
      content: '';
      border-radius: 50%;
      background: var(--color-toggle-switch-fg);
      grid-column: 2;
      transition: background 0.2s;
    }
  }
  input:checked {
    + .switch {
      grid-template-columns: 1fr 1fr 0fr;
      &::after {
        background-color: var(--color-toggle-switch-fg-active);
      }
    }
  }
  &:has(input:checked) {
    background: var(--color-toggle-switch-bg-active);
  }
  &:focus {
    border-radius: calc(var(--height) / 2);
  }
`

export default function ToggleSwitch({ onClick, checked }: ToggleSwitchProps) {
  const [playSwitchOn] = useSound(switchOn)
  const [playSwitchOff] = useSound(switchOff)
  const SwitchRef = useRef(null)
  const { allowSound } = useContext(SoundContext)

  const enableKeyControls = function (e: KeyboardEvent) {
    if (!e) return
    e.preventDefault()
    if ((e.key === 'Enter' || e.code === 'Space') && SwitchRef.current === document.activeElement) {
      const toggleSwitch = SwitchRef.current.querySelector('input')
      toggleSwitch.click()
    }
  }

  useEffect(() => {
    document.addEventListener('keypress', enableKeyControls)
    return () => document.removeEventListener('keypress', enableKeyControls)
  }, [SwitchRef])

  return (
    <StyledToggleSwitch ref={SwitchRef} tabIndex={0}>
      <input
        onClick={e => {
          if (allowSound) {
            if (e.currentTarget.checked) {
              playSwitchOn()
            } else {
              playSwitchOff()
            }
          }
          onClick()
        }}
        type='checkbox'
        defaultChecked={checked}
        tabIndex={-1}
        className='non-focusable'
      />
      <div className='switch'></div>
    </StyledToggleSwitch>
  )
}
