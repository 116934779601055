import React from 'react'
import NavWithMobileMenu from './NavWithMobileMenu'

export default function MainNav() {
  const list = [
    // don't think there needs to be a home link these days
    // {
    //   text: 'Home',
    //   href: '/',
    // },
    {
      text: 'Portfolio',
      href: '/portfolio',
    },
    {
      text: 'About',
      href: '/about',
    },
    {
      text: 'Resume',
      href: '/resume',
    },
    {
      text: '/uses',
      href: '/uses',
    },
    {
      text: 'Contact',
      href: '/contact',
    },
  ]
  return <NavWithMobileMenu list={list} className='nav nav--main' />
}
