import React, { createContext, useState } from 'react'

type ModalDataProps = {
  title?: string | JSX.Element
  content: JSX.Element
  onClose?: (x?: any) => any
  size?: 'lg' | 'sm'
}

const initialContext: {
  modalData?: ModalDataProps
  setModalData: React.Dispatch<React.SetStateAction<ModalDataProps>>
} = {
  modalData: null,
  setModalData: null,
}

const ModalContext = createContext(initialContext)

type ModalProviderProps = {
  children: React.ReactNode
}

function ModalProvider({ children }: ModalProviderProps) {
  const [modalData, setModalData] = useState<ModalDataProps>(initialContext.modalData)
  return <ModalContext.Provider value={{ modalData, setModalData }}>{children}</ModalContext.Provider>
}

export { ModalContext, ModalProvider }
