import React, { useContext } from 'react'
import { ThemeContext } from '../context/ThemeContext'
import Icon from './Icon'
import MoonIcon from './icons/Moon'
import SunIcon from './icons/Sun'

export default function ThemeToggle() {
  const { currentThemeMode, setThemeMode } = useContext(ThemeContext)
  if (!currentThemeMode) return null

  const toggleTheme = function () {
    setThemeMode(currentThemeMode === 'dark' ? 'light' : 'dark')
  }

  const adaHelperText = `Current theme is ${currentThemeMode} mode. Select to toggle site to ${
    currentThemeMode === 'dark' ? 'light' : 'dark'
  } mode.`

  return (
    <Icon button onClick={() => toggleTheme()} ariaLabel={adaHelperText} title={adaHelperText}>
      {currentThemeMode === 'dark' ? <SunIcon /> : <MoonIcon />}
    </Icon>
  )
}
